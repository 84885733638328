import React from 'react';
import { Card } from 'react-bootstrap';
import Spinner from '../layouts/Spinner';
import { connect } from 'react-redux';
import {
    getPickAndPack,
    scanBatch,
    batchChecker,
    handleInputChange,
    scanProduct,
    handleSubmit,
} from '../../redux/actions/pick_and_pack/pick_and_pack.actions';

function Picking({
    batch_data,
    search,
    handleInputChange,
    scanProduct,
    handleSubmit,
    loading,
}) {
    // console.log('batch', batch_data);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <section id="with-other-options" style={{ flex: '1' }}>
                <div className="d-flex justify-content-between align-items-center p-1">
                    <p className="text-uppercase mb-0">Scan Batch#</p>
                    <h5 className="text-uppercase border border-dark p-1 mb-0">
                        {batch_data.batch_no}
                    </h5>
                </div>
                <div className="row match-height">
                    <div className="col-xl-4 col-lg-6 col-md-12">
                        <Card>
                            <Card.Header>
                                <div className="d-flex justify-content-between align-items-center w-100">
                                    <div className="d-flex flex-column">
                                        <select
                                            className="form-select"
                                            aria-label="Sales Order List"
                                            value=""
                                            onChange={(e) => e.preventDefault()}
                                        >
                                            <option value="" hidden>
                                                Sales Order List
                                            </option>
                                            {batch_data.sales_orders &&
                                            batch_data.sales_orders.length >
                                                0 ? (
                                                batch_data.sales_orders.map(
                                                    (order, index) => (
                                                        <option
                                                            key={index}
                                                            value={order.so_no}
                                                        >
                                                            {order.so_no}
                                                        </option>
                                                    )
                                                )
                                            ) : (
                                                <option>No Sales Orders</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="heading-elements">
                                        <p className="mb-0">
                                            {batch_data.total_items} Items Left
                                        </p>
                                    </div>
                                </div>
                            </Card.Header>
                            {loading ? (
                                <Spinner />
                            ) : (
                                <>
                                    <Card.Body
                                        style={{
                                            border: '1px solid #ddd',
                                            borderRadius: '5px',
                                            padding: '15px',
                                            maxHeight: '300px',
                                            overflowY: 'auto',
                                        }}
                                    >
                                        {batch_data.sales_order_items &&
                                        batch_data.sales_order_items.length >
                                            0 ? (
                                            <div className="item-list">
                                                <div className="row mb-2 text-center">
                                                    <div className="col-4">
                                                        <strong>Product</strong>
                                                    </div>
                                                    <div className="col-4">
                                                        <strong>Qty</strong>
                                                    </div>
                                                    <div className="col-4">
                                                        <strong>
                                                            Location
                                                        </strong>
                                                    </div>
                                                </div>
                                                {batch_data.sales_order_items.map(
                                                    (item, index) => (
                                                        <div
                                                            key={index}
                                                            className="row mb-2 text-center"
                                                            style={{
                                                                color:
                                                                    item.pick_status ===
                                                                    'picked'
                                                                        ? 'green'
                                                                        : 'red',
                                                            }}
                                                        >
                                                            <div className="col-4 font-weight-bold">
                                                                {
                                                                    item.product_name
                                                                }
                                                            </div>
                                                            <div className="col-4">
                                                                {item.qty}
                                                            </div>
                                                            <div className="col-4">
                                                                {item.location}
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        ) : (
                                            <p className="text-center">
                                                No items in this batch.
                                            </p>
                                        )}
                                    </Card.Body>
                                </>
                            )}
                        </Card>
                    </div>
                </div>
            </section>

            <footer
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: '#f8f9fa',
                    width: '100%',
                }}
            >
                {batch_data.sales_order_items.length === 0 ? (
                    <button
                        className="btn btn-primary pick-btn w-100 mt-2"
                        onClick={() => handleSubmit(batch_data._id)}
                    >
                        Complete
                    </button>
                ) : (
                    <>
                        <div className="form-group mb-0">
                            <input
                                type="text"
                                id="search"
                                value={search}
                                onChange={handleInputChange}
                                name="search"
                                className="form-control pick-input"
                                placeholder="Enter SKU/Barcode"
                                required
                            />
                        </div>{' '}
                        <button
                            className="btn btn-primary pick-btn w-100 mt-2"
                            onClick={() => scanProduct(search)}
                        >
                            Scan
                        </button>
                    </>
                )}
            </footer>
        </div>
    );
}

const mapStateToProps = (state) => ({
    pick_and_pack: state.pick_and_pack,
});

export default connect(mapStateToProps, {
    getPickAndPack,
    scanBatch,
    batchChecker,
    handleInputChange,
    scanProduct,
    handleSubmit,
})(Picking);
