import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import permission, {PermissionContext } from 'react-access-permission';
import { useHistory, useLocation } from 'react-router-dom';

// UTILITIES
import { getToken, removeUserSession, setUserSession, setUserAccess, getUserAccess, getUserPermissions } from './Utils/Common';
import PrivateRoute from './Utils/PrivateRoute';
import PublicRoute from './Utils/PublicRoute';
import {API} from './Services/_user.service';

// Redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import {  persistor, configureStore  }  from './store';
import { PermissionProvider, Show, useAccess } from "react-redux-permission";
import { LastLocationProvider } from "react-router-last-location";

// Components
import Navbar from "./components/layouts/Navbar";
import Footer from "./components/layouts/Footer";
import Home from "./components/admin/Home";

import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import Register from "./components/auth/Register";
import Login from "./components/login/LoginV2";
import PageNotFound from "./components/layouts/PageNotFound";
import Product from "./components/product/Product";
import ProductCreate from "./components/product/Product.create";
import ProductEdit from "./components/product/Product.edit";
import Bundle from "./components/bundle/Bundle.js";
import * as AttrTemplate from "./components/attribute_template";
import Customer from "./components/customer/Customer";
import CustomerCreate from "./components/customer/Customer.create";
import CustomerEdit from "./components/customer/Customer.edit";
import CustomerHistory from "./components/customer/Customer.history";
import Supplier from "./components/supplier/Supplier";
import SupplierCreate from "./components/supplier/Supplier.create";
import SupplierEdit from "./components/supplier/Supplier.edit";
import SupplierHistory from "./components/supplier/Supplier.history";
import Agent from "./components/agent/Agent";
import Brand from "./components/brand/Brand";
import BrandCreate from "./components/brand/Brand.create";
import BrandEdit from "./components/brand/Brand.edit";
import Category from "./components/category/Category";
import CategoryCreate from "./components/category/Category.create";
import CategoryEdit from "./components/category/Category.edit";
import PurchaseOrder from "./components/purchase_order/PurchaseOrder";
import PurchaseOrderCreate from "./components/purchase_order/PurchaseOrder.create";
import PurchaseOrderEdit from "./components/purchase_order/PurchaseOrder.edit";
import Quotation from "./components/quotation/Quotation";
import QuotationCreate from "./components/quotation/Quotation.create";
import QuotationEdit from "./components/quotation/Quotation.edit";
import QuotationCreateInvoice from "./components/quotation/Quotation.create.invoice.js";
import Invoice from './components/invoice/Invoice.js';
import InvoiceCreate from './components/invoice/Invoice.create.js';
import InvoiceEdit from './components/invoice/Invoice.edit.js';

import QuotationCreateSalesOrder from "./components/quotation/Quotation.create.salesorder";
import SalesOrderCreate from './components/sales_order/SalesOrder.create';
import SalesOrderEdit from './components/sales_order/SalesOrder.edit';
import SalesOrder from './components/sales_order/SalesOrder';

import NoAccess from "./components/noaccess/NoAccess";
import ProductUnit from './components/product_unit/ProductUnit';
import ProductUnitCreate from './components/product_unit/ProductUnit.create';
import ProductUnitEdit from './components/product_unit/ProductUnit.edit';
import Receiving from './components/receiving/Receiving';
import Receiving_Create from './components/receiving/Receiving_Create';
import ReceivingEdit from './components/receiving/ReceivingEdit';
import Inventory from "./components/inventory/Inventory";
import InventoryView from "./components/inventory/Inventory.view";
import InventoryProduct from "./components/inventory/products/Products";
import ReceivingAdjustment from './components/receiving_adjustment/Adjustment';
import AdjustmentCreate from './components/receiving_adjustment/Adjustment_Create';
import Delivery from './components/delivery/Delivery';
import DeliveryCreate from './components/delivery/DeliveryCreate';
import DeliveryEdit from './components/delivery/DeliveryEdit';
import DeliveryAdjustment from './components/delivery_adjustment/DeliveryAdjustment';
import DeliveryAdjCreate from './components/delivery_adjustment/DeliveryAdjCreate';
import Profile from './components/profile/Profile';
import Profile_Edit from './components/profile/ProfileEdit';
import Profile_Change_Sub from './components/profile/ProfileChangeSubscription';
import Profile_Invoice from './components/profile/ProfileInvoice';
import Profile_Receipts from './components/profile/ProfileReceipts'; 
import Profile_Change_Billing from './components/profile/ProfileChangeBilling';  
import Profile_Subcription from './components/profile/ProfileSubscription';
import Profile_InvoiceReceipts from './components/profile/ProfileInvoiceReceipts';
import Security from './components/profile/Security';
import EmailSetting from './components/Settings/email/EmailSetting';
  
import POS from "./components/pos/POS";
import POSCreate from "./components/pos/create/POSCreate";
import POSTransaction from "./components/pos/postransaction/POS.transaction";
import POSSetting from "./components/pos/POS.setting";
import * as Settings from "./components/Settings"; 
import ShippingCosts from "./components/shipping_costs/ShippingCosts"
import ShippingCostsCreate from "./components/shipping_costs/ShippingCost.create"
import ShippingCostEdit from "./components/shipping_costs/ShippingCostEdit";
import history from './history';
import CollectionReceipt from "./components/collection_receipts/CollectionReceipt";
import DailySales from "./components/dashboard/DailySales.js";
import PickAndPack from "./components/pick_and_pack/PickAndPack.js";
import Pack from "./components/pick_and_pack/Pack";
import Pick from "./components/pick_and_pack/Pick.js";

// Function to conditionally render layout
const shouldShowLayout = (pathname) => {
    // Add paths where you don't want to show the layout (navbar, footer, etc.)
    const noLayoutPaths = ['/fulfillment/pick-and-pack/pick'];
    return !noLayoutPaths.includes(pathname);
  };
class App extends Component {
  constructor(props) {
    console.log(props);
    super(props);
    this.state = {authLoading: true, setAuthLoading: false, token: '', isLoggedIn: false, userData : {}};

  }

  verifyToken = () => {
    const token = getToken();
    if (!token) { this.setState({authLoading: false}); return; }

    this.setState({authLoading: true, token});
    API.verifyToken().then(response => {

      // setUserAccess(response.data);
      setUserSession(token, response.data.user);
      this.setState({authLoading: false, token, isLoggedIn: true, userData: response});
    }).catch(error => {
      removeUserSession();
      this.setState({authLoading: false});
    });
  }

  noAccessDiv() {
    return(
      <div className="app-content content">
        <div className="content-wrapper">
            <div className="content-wrapper-before"></div>
            <div className="content-header row">
            </div>
            <div className="content-body">
                <section className="flexbox-container">
                    <div className="col-12 d-flex align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-6 col-10 box-shadow-2 p-0">
                            <div className="card border-grey border-lighten-3 px-1 py-1 m-0">
                                <div className="card-header border-0">
                                    <div className="font-large-1  text-center text-danger">
                                        You have no access to this page. Please contact the administrator.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </div>
      </div>
    )
  }
  

  componentDidMount() {
    this.verifyToken();
  }
  render() {
    const { pathname } = window.location;

    return (
      <Router history={history}>
          <LastLocationProvider>
          <PersistGate persistor={persistor}>
              <React.Fragment>
                {/* SHOW NAVIGATION WHEN LOGGED IN */}
                {shouldShowLayout(pathname) && <Navbar />}
              
                <Switch>
                  {/* PLASE FOLLOW PublicRouting AND PrivateRouting */}
                    <PublicRoute path="/forgot-password" exact component={ForgotPassword}/>
                    <PublicRoute path="/sign-up" exact component={Register}/>
                    <PublicRoute path="/login" exact component={Login}/>
                    <PublicRoute path="/reset-password/:token" exact component={ResetPassword}/>
                    
                    {/* <PrivateRoute permission="inv.dashboard" path="/" exact component={Home} /> */}
                    <PrivateRoute permission="inv.products" path="/" exact component={Inventory} />
                    <PrivateRoute permission="inv.dashboard" path="/dashboard" exact component={Home} />
                    <PrivateRoute permission="inv.dashboard.daily-sales" path="/dashboard/daily-sales" exact component={DailySales} />

                    {/* Products*/}
                    <PrivateRoute permission="inv.products.items" path="/products/items" exact component={Product} />
                    <PrivateRoute permission="inv.products.items.create" path="/inventory/products/items/create" exact component={ProductCreate} />
                    <PrivateRoute permission="inv.products.items.edit" path="/inventory/products/items/edit/:id" exact component={ProductEdit} />
                    <PrivateRoute permission="inv.products.bundle" path="/products/bundle" exact component={Bundle} />
                    <PrivateRoute permission="inv.products.attribute-template" path="/products/attribute-template" exact component={AttrTemplate.AttributeTemplate} />
                    <PrivateRoute permission="inv.products.attribute-template.create" path="/inventory/products/attribute-template/create" exact component={AttrTemplate.AttributeTemplateCreate} />
                    <PrivateRoute permission="inv.products.attribute-template.edit" path="/inventory/products/attribute-template/edit/:id" exact component={AttrTemplate.AttributeTemplateEdit} />
                    <PrivateRoute permission="inv.products.brands" path="/products/brands" exact component={Brand} />
                    <PrivateRoute permission="inv.products.brands.create" path="/inventory/products/brands/create" exact component={BrandCreate} />
                    <PrivateRoute permission="inv.products.brands.edit" path="/inventory/products/brands/edit/:id" exact component={BrandEdit} />
                    <PrivateRoute permission="inv.products.categories" path="/products/categories" exact component={Category} />
                    <PrivateRoute permission="inv.products.categories.create" path="/inventory/products/categories/create" exact component={CategoryCreate} />
                    <PrivateRoute permission="inv.products.categories.edit" path="/inventory/products/categories/edit/:id" exact component={CategoryEdit} />

                    <PrivateRoute permission="inv.inventory.all" path="/inventory" exact component={Inventory} />
                    <PrivateRoute permission="inv.inventory.all.edit" path="/inventory/view/:id" exact component={InventoryView} />
                    <PrivateRoute permission="inv.products" path="/inventory/products" exact component={InventoryProduct} />

                    {/* Purchases */}
                    <PrivateRoute permission="inv.purchases.purchase-orders" path="/purchases/purchase-orders" exact component={PurchaseOrder} />
                    <PrivateRoute permission="inv.purchases.purchase-orders.create"path="/purchases/purchase-orders/create" exact component={PurchaseOrderCreate} />
                    <PrivateRoute permission="inv.purchases.purchase-orders.edit"path="/purchases/purchase-orders/edit/:id" exact component={PurchaseOrderEdit} />
                    <PrivateRoute permission="inv.shipping-costs"path="/purchases/shipping-costs" exact component={ShippingCosts} />
                    
                    <PrivateRoute permission="inv.shipping-costs.store"path="/purchases/shipping-costs/create" exact component={ShippingCostsCreate} />
                    <PrivateRoute permission="inv.shipping-costs.store"path="/purchases/shipping-costs/edit/:id" exact component={ShippingCostEdit} />
                    <PrivateRoute permission="inv.purchases.suppliers"path="/purchases/suppliers" exact component={Supplier} />
                    <PrivateRoute permission="inv.purchases.suppliers.create"path="/purchases/suppliers/create" exact component={SupplierCreate} />
                    <PrivateRoute permission="inv.purchases.suppliers.edit"path="/purchases/suppliers/edit/:id" exact component={SupplierEdit} />
                    <PrivateRoute permission="inv.purchases.suppliers.history"path="/purchases/suppliers/history/:id" exact component={SupplierHistory} />

                    {/* Sales */}
                    <PrivateRoute permission="inv.sales.quotations" path="/sales/quotations" exact component={Quotation} />
                    <PrivateRoute permission="inv.sales.quotations.create" path="/sales/quotations/create" exact component={QuotationCreate} />
                    <PrivateRoute permission="inv.sales.quotations.edit" path="/sales/quotations/edit/:id" exact component={QuotationEdit} />
                    <PrivateRoute permission="inv.sales.invoices.create" path="/sales/quotations/create-invoice/:id" exact component={QuotationCreateInvoice} />
                    <PrivateRoute permission="inv.sales.invoices" path="/sales/invoices" exact component={Invoice} />
                    <PrivateRoute permission="inv.sales.invoices.create" path="/sales/invoices/create" exact component={InvoiceCreate} />
                    <PrivateRoute permission="inv.sales.invoices.edit" path="/sales/invoices/edit/:id" exact component={InvoiceEdit} />
                    <PrivateRoute permission="inv.sales.customers" path="/sales/customers" exact component={Customer} />
                    <PrivateRoute permission="inv.sales.customers.create" path="/sales/customers/create" exact component={CustomerCreate} />
                    <PrivateRoute permission="inv.sales.customers.edit" path="/sales/customers/edit/:id" exact component={CustomerEdit} />
                    <PrivateRoute permission="inv.sales.customers.history" path="/sales/customers/history/:id" exact component={CustomerHistory} />

                    {/* Sales Order*/}
                    <PrivateRoute permission="inv.sales.sales-orders" path="/sales/sales-orders" exact component={SalesOrder} />
                    <PrivateRoute permission="inv.sales.sales-orders.create" path="/sales/quotations/create-sales-order/:id" exact component={QuotationCreateSalesOrder} />
                    <PrivateRoute permission="inv.sales.sales-orders.create" path="/sales/sales-orders/create" exact component={SalesOrderCreate} />
                    <PrivateRoute permission="inv.sales.sales-orders.edit" path="/sales/sales-orders/edit/:id" exact component={SalesOrderEdit} />
                    <PrivateRoute permission="inv.setting.forms.sales-orders"path="/settings/sales" exact component={Settings.SalesOrderSettings} />
                    <PrivateRoute permission="inv.setting.forms.sales-orders.payment-terms"path="/settings/terms-conditions/create" exact component={Settings.TermConditionCreate}/>
                    <PrivateRoute permission="inv.setting.forms.sales-orders.payment-terms"path="/settings/terms-conditions/edit/:id" exact component={Settings.TermConditionEdit}/>
                    
                    {/* <PrivateRoute permission="inv.dashboard" path="/account/order_receipts" exact component={Profile_OrderReceipts}/>
                    <PrivateRoute permission="inv.dashboard" path="/account/profile/order" exact component={Profile_Order}/> */}
                    
                    {/* <PrivateRoute permission="inv.sales.agents" path="/sales/agents" exact component={Agent} /> */}
            
                    
                    {/* POS */}
                    <PrivateRoute permission="inv.sales.pos" path="/sales/pos" exact component={POS} />
                    {/* inv.sales.pos.transaction */}
                    <PrivateRoute permission="inv.sales.pos" path="/sales/pos/transaction" exact component={POSTransaction} />
                    {/* inv.sales.pos.create */}
                    <PrivateRoute permission="inv.sales.pos.create" path="/sales/pos/create" exact component={POSCreate} />
                    {/* inv.sales.pos.setting */}
                    <PrivateRoute permission="inv.sales.pos" path="/sales/pos/setting" exact component={POSSetting} />
                  
                    {/* Setting */}
                    <PrivateRoute permission="inv.setting.app" path="/settings/app" exact component={Settings.SettingApp} />

                    <PrivateRoute permission="inv.setting.template"path="/settings/template" exact component={Settings.TemplateSetting} />
                    <PrivateRoute permission="inv.setting.system"path="/settings/system" exact component={Settings.SystemSettings} />
                    <PrivateRoute permission="inv.setting.forms.invoices"path="/settings/sales" exact component={Settings.SalesInvoiceSettings} />
                    <PrivateRoute permission="inv.setting.forms.invoices.payment-terms"path="/settings/terms-conditions/create" exact component={Settings.TermConditionCreate}/>
                    <PrivateRoute permission="inv.setting.forms.invoices.payment-terms"path="/settings/terms-conditions/edit/:id" exact component={Settings.TermConditionEdit}/>
                    <PrivateRoute permission="inv.setting.forms"path="/settings/forms" exact component={Settings.FormSettings}/>
                    <PrivateRoute permission="inv.setting.users"path="/settings/users" exact component={Settings.Users}/>
                    <PrivateRoute permission="inv.setting.users.roles.create"path="/settings/users/role/create" exact component={Settings.RoleCreate}/>
                    <PrivateRoute permission="inv.setting.users.roles.edit"path="/settings/users/role/edit/:id" exact component={Settings.RoleEdit}/>
                    <PrivateRoute permission="inv.setting.users.accounts.create"path="/settings/users/account/create" exact component={Settings.AccountCreate}/>
                    <PrivateRoute permission="inv.setting.users.accounts.edit"path="/settings/users/account/edit/:id" exact component={Settings.AccountEdit}/>

                    <PrivateRoute permission="inv.setting.product-unit"path="/settings/product-units" exact component={ProductUnit} />
                    <PrivateRoute permission="inv.setting.product-unit.create"path="/settings/product-units/create" exact component={ProductUnitCreate} />
                    <PrivateRoute permission="inv.setting.product-unit.edit"path="/settings/product-units/edit/:id" exact component={ProductUnitEdit} />

                    {/* email settings */}
                    <PrivateRoute permission="inv.setting.email"path="/settings/email" exact component={EmailSetting}/>

                    {/* Receiving */}
                    <PrivateRoute permission="inv.receiving.itemReceipts"path="/receivings" exact component={Receiving} />
                    <PrivateRoute permission="inv.receiving.itemReceipts.create"path="/receivings/create" exact component={Receiving_Create} />
                    <PrivateRoute permission="inv.receiving.itemReceipts.edit"path="/receivings/edit/:id" exact component={ReceivingEdit} />
                    <PrivateRoute permission="inv.receiving.adjustment.view"path="/receiving/adjustment" exact component={ReceivingAdjustment} />
                    <PrivateRoute permission="inv.receiving.adjustment.create"path="/receiving/adjustment/create" exact component={AdjustmentCreate} />

                    {/* Delivery */}
                    <PrivateRoute permission="inv.delivery.receipts.view" path="/deliveries" exact component={Delivery} />
                    <PrivateRoute permission="inv.delivery.receipts.create" path="/deliveries/create" exact component={DeliveryCreate} />
                    <PrivateRoute permission="inv.delivery.receipts.edit" path="/deliveries/edit/:id" exact component={DeliveryEdit} />

                    <PrivateRoute permission="inv.delivery.adjustment.view" path="/delivery/adjustment" exact component={DeliveryAdjustment} />
                    <PrivateRoute permission="inv.delivery.adjustment.create" path="/delivery/adjustment/create" exact component={DeliveryAdjCreate} />


                    {/* Collections */}
                    <PrivateRoute permission="inv.collections.receipts.view" path="/collections/collection-receipts" exact component={CollectionReceipt} />
                    
                    {/* PickAndPack */}
                    <PrivateRoute permission="inv.fulfillment.pick-and-pack.view" path="/fulfillment/pick-and-pack" exact component={PickAndPack} />
                    <PrivateRoute permission="inv.fulfillment.pick-and-pack.pack" path="/fulfillment/pick-and-pack/pick" exact component={Pick} />

                    {/* Profile */}
                    <PrivateRoute permission="inv.dashboard" path="/account/profile" exact component={Profile}/>
                    <PrivateRoute permission="inv.dashboard" path="/account/profile/subcription" exact component={Profile_Subcription}/>
                    <PrivateRoute permission="inv.dashboard" path="/account/invoice_receipts" exact component={Profile_InvoiceReceipts}/>
                    <PrivateRoute permission="inv.dashboard" path="/account/profile/profile_change_subscription" exact component={Profile_Change_Sub}/>
                    <PrivateRoute permission="inv.dashboard" path="/account/profile/invoice" exact component={Profile_Invoice}/>
                    <PrivateRoute permission="inv.dashboard" path="/account/profile/receipts" exact component={Profile_Receipts}/>
                    <PrivateRoute permission="inv.dashboard" path="/account/profile/change_billing" exact component={Profile_Change_Billing}/>
                    <PrivateRoute permission="inv.dashboard" path="/account/security" exact component={Security}/>
                    <PrivateRoute permission="inv.dashboard" path="/no/access" exact component={NoAccess} />
                    <PublicRoute component={PageNotFound} />
                </Switch>
              </React.Fragment>
            </PersistGate>
          </LastLocationProvider>
      </Router>
    );
  }
}


export default App;
